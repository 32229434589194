import { Injectable, Signal } from '@angular/core';
import { patchState, signalState } from '@ngrx/signals';

export type Brand = {
  readonly id: string;
  readonly title: string;
  readonly color: string;
  readonly imageUrl: string;
  readonly iconUrl: string;
  readonly transparentIconUrl: string;
  readonly textTheme: 'light' | 'dark';
  readonly buttonTheme: 'light' | 'dark';
};

export type BrandsStateType = {
  readonly brands: readonly Brand[];
};

@Injectable({
  providedIn: 'root',
})
export class BrandsState {
  readonly #brandsState = signalState<BrandsStateType>({ brands: [] });

  readonly getBrandById = (id: string): Brand | undefined => {
    return this.#brandsState.brands().find((brand) => brand.id === id);
  };

  readonly getBrandByTitle = (title: string): Brand | undefined => {
    return this.#brandsState.brands().find((brand) => brand.title === title);
  };

  get brands(): Signal<readonly Brand[]> {
    return this.#brandsState.brands;
  }

  set brands(brands: Brand[]) {
    patchState(this.#brandsState, { brands });
  }
}
